import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Expertise from '../components/Expertise'

const ExpertisePage = () => (
    <Layout>
        <SEO title="expertise:title" />
        <Expertise />
    </Layout>
)

export default ExpertisePage
